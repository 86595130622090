<template>
  <div id="content-wrapper">
    <TopNavbar :user-profile="userProfile" />
    <div v-if="!projectDataReady" class="loading">
      {{ trans('global.loading') }}
    </div>
    <div v-else id="content">
      <div id="project-topbar">
        <b-container fluid>
          <b-row>
            <b-col class="projectname padding-top-30">
              <p class="my-0 text-muted font-weight-light">
                {{ project.name }}
              </p>
              <h2>{{ trans('global.sales') }}</h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="tabnav p-0">
              <b-nav class="nav-underlined">
                <b-nav-item :active="activeTab === 'dashboard'" @click="toggleTab('dashboard')">
                  {{ trans('global.overview') }}
                </b-nav-item>
                <b-nav-item :active="activeTab === 'styletypes'" @click="toggleTab('styletypes')">
                  {{ trans('global.styletypes') }}
                </b-nav-item>
                <b-nav-item :active="activeTab === 'prospects'" @click="toggleTab('prospects')">
                  {{ trans('global.prospects') }}
                </b-nav-item>
                <b-nav-item :active="activeTab === 'chooser'" @click="toggleTab('chooser')">
                  {{ trans('global.chooser') }}
                </b-nav-item>
                <b-nav-item :active="activeTab === 'settings'" @click="toggleTab('settings')">
                  {{ trans('global.settings') }}
                </b-nav-item>
              </b-nav>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <ProjectSalesDashboard
        v-if="activeTab === 'dashboard'"
        id="project-sales-dashboard"
        :project="project"
        :project-type="projectType"
        :project-units="projectUnits"
        :project-prospects="projectProspects"
      ></ProjectSalesDashboard>
      <ProjectSalesProspects
        v-if="activeTab === 'prospects'"
        id="project-sales-prospects"
        :project="project"
        :project-type="projectType"
        :project-units="projectUnits"
        :project-prospects="projectProspects"
      ></ProjectSalesProspects>
      <ProjectSalesChooser v-if="activeTab === 'chooser'" id="project-sales-chooser" :project="project"></ProjectSalesChooser>
      <ProjectSalesSettings
        v-if="activeTab === 'settings'"
        id="project-sales-settings"
        :project="project"
        :project-type="projectType"
        :project-units="projectUnits"
        :project-prospects="projectProspects"
      ></ProjectSalesSettings>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  const TopNavbar = () => import('../../../components/Navigation/TopNavbar')
  const ProjectSalesDashboard = () => import('../../../components/Views/Projects/Sales/Dashboard')
  const ProjectSalesProspects = () => import('../../../components/Views/Projects/Sales/Prospects')
  const ProjectSalesSettings = () => import('../../../components/Views/Projects/Sales/Settings')
  const ProjectSalesChooser = () => import('../../../components/Views/Projects/Sales/Chooser')

  export default {
    name: 'ProjectSalesIndex',
    components: {
      TopNavbar,
      ProjectSalesDashboard,
      ProjectSalesProspects,
      ProjectSalesSettings,
      ProjectSalesChooser
    },
    data () {
      return {
        projectTypes: {
          1: 'cabin',
          2: 'apartment',
          3: 'house',
          4: 'industry',
          5: 'unit'
        }
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile,
        projects: state => state.projects.projects,
        configs: state => state.configs,
        units: state => state.units.units,
        prospects: state => state.prospects.prospects
      }),
      projectDataReady () {
        return this.project && this.prospects
      },
      project () {
        return this.projects.find(project => project.slug === this.$route.params.projectslug)
      },
      activeTab () {
        return (this.configs.projectTopbarMenu !== undefined && this.configs.projectTopbarMenu.activeTab !== '') ? this.configs.projectTopbarMenu.activeTab : 'dashboard'
      },
      projectType () {
        return this.project.type !== undefined ? this.projectTypes[this.project.type] : 1
      },
      projectUnits () {
        return this.units.filter(unit => unit.projectId === this.project._id)
      },
      projectProspects () {
        return this.prospects.filter(prospect => prospect.projectId === this.project._id)
      }
    },
    beforeMount () {
      this.$store.commit('configs/setProjectTopbarMenu', 'dashboard')
      this.updateBreadcrumb(
        [
          { text: this.trans('global.projects'), to: '/projects' },
          {
            text:
              {
                store: 'projects',
                searchin: 'slug',
                searchfor: this.$route.params.projectslug
              },
            to: '/projects/' + this.$route.params.projectslug
          },
          {
            text: this.trans('global.sales'), to: '#'
          }
        ]
      )
    },
    methods: {
      toggleTab (tab) {
        this.$store.commit('configs/setProjectTopbarMenu', tab)
      }
    }
  }
</script>
